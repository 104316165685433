<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
.page {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>
<style scoped>
  html,body{
    font-size: 16px;
  }

.w-99 {
  width: 99%;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.style-set-box {
  border-bottom: unset;
}

.less-width .w50 {
  width: 40.5%;
}

.less-width .w50:nth-child(2n) {
  width: 59.5% !important;
}

.guding-top {
  width: 80%;
}
.exam-form {
    padding-left: 20px;
}

.top-height {
  max-height: 160px;
  line-height: 160px;
}

.msgLabel {
  color: #000000;
}

.msgLabel {
  align-items: flex-start;
  min-height: 45px;
  height: initial
}
.style-name {
  flex: none;
}

.cjd_title {
  width: 100%;
  /* min-height: 50px; */
  /* line-height: 50px; */
  font-size: 24px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #000000;
}

.bottom-info {
  /* max-height: 100px; */
}

.guding-col {
  flex-wrap: wrap;
  box-sizing: border-box;
}

.guding-col .msgLabel:nth-child(n-1) {
  border-bottom: unset;
}

.guding-img {
  width: 20%;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
}

.style-right {
  background-size: 100% 100%;
  min-height: 500px;
  font-size: 18px;
  padding:0 25px 0 65px;
  background-repeat: no-repeat;
}
</style>
<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import $ from "jquery";
// import {
//   getExamDetails,
// } from "@/api/admin/exam/examRecord.js"
import { getCjkInfo } from "@/api/score/scoreKu.js";
import { getTemplateInfo } from "@/api/score/template.js";
import { getCjDetails } from "@/api/score/score.js";
import { changeUrl } from "@/api/common/public.js";

export default {
  name: "clone-on-control",
  display: "Clone on Control",
  instruction: "Press Ctrl to clone element from list 1",
  order: 4,
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "成绩单样式 ",
      items: [
        {
          text: "用户首页",
          href: "/admin",
        },
        {
          text: "成绩单样式预览",
          active: true,
        },
      ],
      list2: [],
      list3: [],
      sid: "",
      userSid: "",
      form: {},
      isShowZp: true,
      zpdz: "",
      mid: "",
      mbh: "",
      mbmc: "",
      mbmc2: "",
      imgId: "",
      imgUrl: "",
      ksbmbh: "",
      defaultZpdz: require("@/assets/images/person/person-none.png"),
      listChoose: [],
      examineeData: {},
      examForm: {
        cjdmc: "",
        cjdzysx: "",
        cjdbzxx: "",
      },
      moduleType: 0,
      pageNum: 0,
      pageHeight: 1103,
      pdfHeight: 0,
      pdfNum: 1,
      totalPage: 1,
      TemplateInfo: {},
    };
  },
  methods: {
    //下载成绩单
    printOrPreview(flag) {
      // flag为真是打印
      let moduleType = this.moduleType;
      this.choosePrintPdf(flag, moduleType);
      /* let height = $("#gradePrint").height()
        console.log(height)
        let num
        if (moduleType == 1) { //横
          num = Math.ceil(height / 780)
          $('#gradePrint').css({
            "height": num * 780 + "px",

          })
          this.choosePrintPdf(flag, moduleType)
          setTimeout(() => {
            $('#gradePrint').css({
              "height": "auto"
            })
          }, 1000)
        } else if (moduleType == 0) {
          num = Math.ceil(height / 1103) // 向上取整，几页
          $('#gradePrint').css({
            "height": num * 1103 + "px"
          })

          this.choosePrintPdf(flag, moduleType)
          setTimeout(() => {
            $('#gradePrint').css({
              "height": "auto"
            })
          }, 1000)
        }
 */
    },
    choosePrintPdf(flag, type) {
      this.ExportSavePdf("成绩单", "pdf", type, flag);
    },
    // 渲染创建好的模板
    getTemplateList(sMid) {
      this.list2 = [];
      this.list3 = [];
      let func;
      let param;
      if (sMid) {
        func = getTemplateInfo;
        param = sMid;
        func(param).then((res1) => {
          if (res1.status) {
            this.TemplateInfo = res1.data;
            let imgid = res1.data.cjbjwj;
            if (imgid) {
              this.getFileUrl(imgid, true).then((res) => {
                if (res.status) {
                  this.imgUrl = res.data;
                  if (this.userSid) {
                    this.$nextTick(() => {
                      this.getList(this.userSid);
                    }
                  )
                  }
                }
              });
            }else{
              if (this.userSid) {
                this.$nextTick(() => {
                          this.getList(this.userSid);
                        }
                )
              }
            }

            if (!res1.data.sfxszp) {
              this.isShowZp = false;
              this.$nextTick(() => {
                this.hiddenZp();
              });
            } else {
              this.isShowZp = true;
              this.hiddenZp();
            }
            if (res1.data.sfhxbj) {
              this.pageHeight = Math.floor((1103 / 297) * 210);
              this.moduleType = 1;
              $("#gradePrint").css("width", "1103px");
              // $(".card").css("width", '1103px')
              let height = Math.floor((1103 / 297) * 210);
              $("#gradePrint").css("height", height + "px");
            } else {
              this.moduleType = 0;
              // $(".card").css("width", '780px')
              $("#gradePrint").css("width", "780px");
              let height = Math.floor((780 / 210) * 297);
              $("#gradePrint").css("height", height + "px");
            }

            this.$nextTick(() => {
              let urls = [];
              $("#gradePrint")
                .find("img")
                .each((x, y) => urls.push(y.src));
              changeUrl(urls).then((res) => {
                $("#gradePrint")
                  .find("img")
                  .each((x, y) => {
                    if (y.src.startsWith("http")) {
                      y.src = res.data[y.src];
                    }
                  });
              });
            });
            res1.data.printTemplateFieldBeanList.forEach((item) => {
              item.zddm = item.zddm.replace("_", "");
              if (this.examForm.zpysyq != 4) {
                if (item.zdfl == 1) {
                  this.list2.push(item);
                } else {
                  this.list3.push(item);
                }
              } else {
                this.list3.push(item);
              }
            });
            return;
          }
        });
      }
    },

    getDetail(sid) {
      getCjkInfo(sid).then((res) => {
        if (res.status) {
          this.examForm = res.data;
          this.mbmc2 = res.data.cjdmc;
          this.ksbmbh = res.data.ksbmbh;
          if (this.mid) {
            this.getTemplateList(this.mid);
          }
          if (this.mbh != "null") {
            this.getTemplateList(this.mbh);
          } else {
            this.$message({
              type: "warning",
              message: "请先创建模板！",
            });
          }
        }
      });
    },
    isHidden() {
      this.isShowZp = !this.isShowZp;
      this.hiddenZp();
    },
    //显示隐藏照片
    hiddenZp() {
      if (this.isShowZp == false) {
        $(".guding-top").css("width", "99%");
        $(".guding-top").removeClass("border-right");
        $(".guding-img").css("display", "none");
        $(".guding-col").addClass("less-width");
      } else {
        $(".guding-img").css("display", "flex");
        $(".guding-top").css("width", "80%");
        $(".guding-top").removeClass("border-right");
        $(".guding-col").removeClass("less-width");
      }
    },
    // 成绩记录
    getList(param) {
      getCjDetails(param).then((res) => {
        if (res.status) {
          this.examineeData = Object.assign({},res.data);

          // console.log(this.examineeData,22)


          let cjkid = this.sid;
          let sfzjh = res.data.sfzjhEnc;
          let fileId = "cjk_" + cjkid + "_" + sfzjh;
          if (this.ksbmbh) {
            fileId = "bm_" + this.ksbmbh + "_" + sfzjh;
          }
          if (this.TemplateInfo.sfxszp) {
            this.getFileUrl(fileId, true).then((res) => {
              if (res.status) {
                this.zpdz = res.data;
                this.$nextTick(()=>{
                  this.pageSign();
                  this.toPdfHtml();
                  $("#gradePrint").hide();
                  $(".page span").html(this.totalPage);
                })
              }
            });
          } else {
            this.$nextTick(()=>{
              this.pageSign();
              this.toPdfHtml();
              $("#gradePrint").hide();
              $(".page span").html(this.totalPage);
            })
          }

        }
      });
    },
    pageSign() {
      let height = $(".outerCotent").height();
      this.pageNum = parseInt(height / this.pageHeight);
    },
    toPdfHtml() {
      $("#pdf").empty();
      $("#pdf").append(
        "<div class='pdf' id='pdf1' page='1' style='height:" +
          this.pageHeight +
          "px;position:relative'></div>"
      );
      // $("#pdf1").append(
      //   "<div class='page' style='height:30px'>" +
      //     this.totalPage +
      //     "/<span></span></div>"
      // );
      // 成绩单内容
      $("#gradePrint")
        .children()
        .children()
        .each((i, item) => {
          // console.log( $(item))
          console.log(this.imgUrl,44)
          this.addItem(item, $(item).outerHeight(true), this.imgUrl);
        });
    },

    addItem(item, height, imgurl) {
      let oldNHum = this.pdfNum;
      let paddingHeight = 0;
      height = Number(height);
      // console.log("this.pdfHeight",this.pdfHeight,"height",height,"paddingHeight",paddingHeight,"this.pageHeight",this.pageHeight)
      // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
      if (
        this.pdfHeight > 0 &&
        parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
          parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
      ) {
        // console.log(this.totalPage,"this.pdfNum",this.pdfNum)
        // pdf页数加1
        this.totalPage += 1;
        this.pdfNum += 1;
        this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
        $("#pdf").append(
          "<div class='pdf' id='pdf" +
            this.pdfNum +
            "' page='" +
            this.pdfNum +
            "'style='height:" +
            this.pageHeight +
            "px;position:relative'></div>"
        );
        // $("#pdf" + this.pdfNum).append(
        //   "<div class='page' style='height:30px'>" +
        //     this.totalPage +
        //     "/<span></span></div>"
        // );
      }
      this.pdfHeight += height;
      // console.log(this.pdfNum , oldNHum)
      // 将内容放入div中
      if ($(item)[0].id == "scoreBottom" && this.pdfNum == oldNHum) {
        $(item).css("position", "absolute");
        $(item).css("bottom", "0");
      }
      $(item)
        .clone()
        .appendTo($("#pdf" + this.pdfNum));
      // $(".pdf").css("border", "1px #000000 solid")
      $(".pdf").css("padding", "0px 25px 0 45px");
      if (imgurl) {
        $(".pdf").css("background-image", "url(" + imgurl + ")");
      }
    },
  },

  mounted() {
    if (this.sid) {
      this.getDetail(this.sid);
    }
  },
  created() {
    this.sid = this.$route.query.sid ? this.$route.query.sid : ""; //成绩库ID
    this.mid = this.$route.query.mid ? this.$route.query.mid : ""; //模板ID
    // this.imgId = this.$route.query.imgId ? this.$route.query.imgId : "" //背景图ID
    this.userSid = this.$route.query.userSid ? this.$route.query.userSid : ""; //个人信息sid
    this.mbh = this.$route.query.mbh ? this.$route.query.mbh : ""; //模板号
    this.mbmc = this.$route.query.mbmc ? this.$route.query.mbmc : ""; //模板名称
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card" style="margin: 0 auto">
      <div class="card-body score-print">
        <div
          id="pdf"
          style="margin: 0 auto"
          :style="{ width: moduleType == 1 ? '1103px' : '780px' }"
        ></div>
        <div class="outerCotent mt-3 score-print">
          <div
            class="style-set-box h-100 d-flex align-items-center justify-content-center"
          >

            <el-row id="gradePrint" class="">
              <!-- :style="{backgroundImage:'url(' +this.styleBg[this.mid].imgUrl+ ')'}" -->


              <el-col
                :span="24"
                class="style-right h-100"
                ref="main"
                id="mians"
                style="min-height: 600px; position: relative"
                :style="{ backgroundImage: 'url(' + imgUrl + ')' }"
              >
                <div class="w-99 cjd_title" style="white-space: pre-wrap">
                  {{ mbmc ? mbmc : mbmc2 }}
                </div>
                <div class="w-99">
                  <div
                     v-if="examForm.cjdzysx"
                    class="text-dark"
                    style="white-space: pre-wrap"
                  >
                    {{ examForm.cjdzysx }}
                  </div>
                </div>
                <div class="mt-3 exam-form" style="max-height: 80%">
                  <el-row style="" class="border-diff top-height">
                    <el-col class="guding-top">
                      <div
                        ref="list1Container"
                        class="flexList guding-col"
                        :list="list2"
                        group="people"
                        style="min-height: 40px"
                      >
                        <div
                          class="flexList msgLabel"
                          :class="[
                            'w' + element.zdbl,
                            element.txlx == 'textarea' ? 'msgtextarea' : '',
                          ]"
                          v-for="element in list2"
                          :key="element.sid"
                        >
                          <div class="style-name mr-2">
                            {{ element.zdymc ? element.zdymc : element.zdmc }}：
                          </div>
                          <div class="style-val flexList">
                            {{ examineeData[element.zddm] }}
                          </div>
                        </div>
                      </div>
                    </el-col>
                    <el-col class="guding-img">
                      <img
                        :src="'data:image/jpeg;base64' +zpdz ? zpdz : defaultZpdz"
                        alt=""
                        style="width: 120px; height: 150px"
                      />
                    </el-col>
                  </el-row>
                  <el-row style="margin-top: -10px">
                    <div
                      style="min-height: 40px; width: 99%; flex-wrap: wrap"
                      class="flexList fs-xs less-width"
                      :list="list3"
                      group="people"
                    >
                      <div
                        class="flexList msgLabel"
                        :class="[
                          'w' + element.zdbl,
                          element.txlx == 'textarea' ? 'msgtextarea' : '',
                        ]"
                        v-for="element in list3"
                        :key="element.sid"
                      >
                        <div class="style-name mr-2">
                          {{ element.zdymc ? element.zdymc : element.zdmc }}：
                        </div>
                        <div class="style-val flexList">
                          {{ examineeData[element.zddm] }}
                        </div>
                      </div>
                    </div>
                  </el-row>
                </div>
                <div  v-if="examForm.cjdbzxx" class="w-99 mb-3" id="scoreBottom">
                  <div
                    class="bottom-info text-dark"
                    style="padding-left: 20px;white-space: pre-wrap"
                  >
                    {{ examForm.cjdbzxx }}
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="mt-3 flexList">
          <!-- <button type="button" class="btn btn-info h30 mr-2" @click="printOrPreview(false)">打印预览</button> -->
          <button
            type="button"
            class="btn btn-info flexList h30"
            @click="printOrPreview(true)"
          >
            <img
              src="@/assets/images/person/file-pdf.png"
              class="mr-2"
            />下载电子成绩单
          </button>
        </div>
      </div>
    </div>

    <!-- 弹窗开始 -->

    <!-- 弹窗结束-->
  </Layout>
</template>
